import React, { useState } from 'react';
import ChatBot from 'react-chatbotify';
import axios from 'axios';
import { json } from 'react-router-dom';



function ZencorpChat(props) {
    const [form, setForm] = React.useState({});
    const formStyle = {
        marginTop: 10,
        marginLeft: 20,
        border: "1px solid #491d8d",
        padding: 10,
        borderRadius: 5,
        maxWidth: 300
    }
    const instance = axios.create({
        baseURL: 'http://localhost:8000',

    });
    const [messages, setMessages] = useState('');
    const controller = new AbortController();
    const abortFetch = () => {
        if (controller) {
            controller.abort()
        }
    }
    const REQUEST_TIMEOUT_MS = 60;

    const requestTimeoutId = setTimeout(
        () => controller.abort(),
        REQUEST_TIMEOUT_MS,
    );

    let responseText = "";
    let remainText = "";
    let finished = false;
    const queryAI = async (params) => {
        const url = "https://zencorpbackend.azurewebsites.net";
        console.log("called AI with: ", params.userInput);
        const formData = new FormData();
        formData.append('question', params.userInput);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question: params.userInput })
        };
        const response = await fetch(url + "/chat_llm", requestOptions);
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
            const { done, value } = await reader.read();
            if (done) break; // Exit the loop if the stream is complete
            const chunk = decoder.decode(value, { stream: true });
            
            await params.injectMessage(chunk);
            console.log(chunk); // Output the chunk, can also be handled differently
        }

        // fetch(url + "/chat_llm", 
        //     { method: 'POST',

        //         body: JSON.stringify({ 'question' : params.userInput}) 
        //     }).then((response) => {

        //     params.injectMessage(response.answer);

        // }).catch(function (error) {

        //     console.log(error) ;
        // });
    }
    let hasError = false;
    const flow = {
        start: {
            message: "Welcome to Zen Corp Chatbot. How can I help you?",
            function: (params) => setForm({ ...form, name: params.userInput }),
            path: () => { return "loop" }
        },
        loop: {
            message: async (params) => {
                await queryAI(params);
            },
            path: () => {
                if (hasError) {
                    return "start"
                }
                return "loop"
            }
        }
    }
    const settings =
    {
        "general":
            { "embedded": false, "showFooter": false }
        ,

        "chatHistory": { "disabled": true, "storageKey": "zencorp" }
        ,

        "header":
            { "title": "Zen Corp Chatbot", "avatar": "images/logo.png" }
        ,

        "notification": { "disabled": false }
        ,

        "tooltip":
            { "text": "How can i help you?" },
        "botBubble":
            { simStream: true }
    };
    return (
        <div class="container">
            <ChatBot settings={settings}

                flow={flow} />

        </div>
    );
}

export default ZencorpChat;