import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './header';
import MainContent from './maincontent';
import Footer from './footer';
import Incorporation from './incorporation';
import Secraterial from './secraterial';
import Tax from './tax';
import Contact from './contact';
import Accounting from './accounting';
import About from './about';
import Payroll from './payroll';
import ScrollToTop from './scrolltotop';
import ZencorpChat from './chat';
 


function App() {
  return (
    

   <BrowserRouter>
    <Header /> 
    <ScrollToTop /> 
   
    <Routes>
      <Route exact path="/" element={<MainContent />} />
        <Route  path="/incorporation" element={<Incorporation />} />
       <Route  path="/secretarial" element={<Secraterial />}/>
      <Route  path="/tax" element={<Tax />}/>
      <Route   path="/contact" element={<Contact />}/>
      <Route   path="/about" element={<About />}/>
      <Route path="/chat" element={<ZencorpChat />}/>
      <Route  path="/accounting" element={<Accounting />}/>
      
      <Route exact path="*" element={() => <h1>Page Not Found</h1>} />
    </Routes>
   <Footer />  
  </BrowserRouter>
   

  );
}

export default App;
